import $ from 'jquery';
import CheckoutGateway from './checkout-gateway';

class LocalCheckoutGateway extends CheckoutGateway {

    constructor(...props) {
        super(...props);
        this.maybeHideGateway();
        this.processRedirectParams();
    }

    create_stripe_elements() {
        if (!this.elements) {
            if (this.isActive()) {
                this.elements = super.create_stripe_elements();
            } else {
                return null;
            }
        }
        return this.elements;
    }

    maybeHideGateway() {
        if (!this.isActive()) {
            $(this.container).hide();
            if (this.is_gateway_selected()) {
                $('li[class*="payment_method_stripe_"]').filter(':visible').eq(0).find('[name="payment_method"]').prop('checked', true).trigger('click');
            }
        } else {
            $(this.container).show();
        }
    }

    isActive() {
        return $('#wc_stripe_local_payment_' + this.gateway_id).data('active');
    }

    updated_checkout(e, data) {
        this.create_stripe_elements();
        this.createPaymentElement();
        super.updated_checkout(e, data);
        this.maybeHideGateway();
    }

    onPaymentElementLoadError(event) {
        const {error} = event;
        $(this.elementSelector).append(error.message);
        this.paymentElement = null;
    }

    async processRedirectParams() {
        const args = this.getHashParameterArgs();
        if (args) {
            // fetch the intent and check its status
            const {client_secret, gateway_id} = args;
            try {
                if (this.gateway_id === gateway_id || this.is_gateway_selected()) {
                    const response = await this.stripe.retrievePaymentIntent(client_secret);
                    if (response.paymentIntent && response.paymentIntent.status === 'requires_action') {
                        if (response.paymentIntent.next_action?.type === 'redirect_to_url') {
                            window.location = response.paymentIntent.next_action.redirect_to_url.url;
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}

export default LocalCheckoutGateway;